@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.button-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.widget-box {
  width: 385px;
  height: 270px;
  border-radius: 12px;
  display: block;
  overflow: hidden;
}

.frame {
  border: 6px solid #fff;
  transition: all 0.2s;
}

.frame::before {
  content: "";
  position: absolute;
  left: 0;
  transition: all 0.2s;
  right: 0;
  border: 3px solid #7f56d9;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  top: 0px;
  left: 0;
  bottom: 0;
}

.react-datepicker-wrapper {
  width: 100%;
}

.Toastify__toast-container {
  z-index: 200000 !important ;
}

