@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.custom-md-editor {
  @apply !h-full min-h-[120px] font-inter block w-full !rounded-md border-0 !p-0 !shadow-sm !ring-1 !ring-inset !ring-gray-300;
  .w-md-editor-text{
    @apply !min-h-[120px];
  }
  .w-md-editor-text-input{
    @apply h-full  !py-2.5 !px-3.5  placeholder:!text-gray-500 text-xs lg:text-sm sm:leading-6 !text-gray-500 focus:text-gray-900 focus:shadow-none focus:ring-2;
  }
}
.custom-md-editor:has(.w-md-editor-text-input:nth-of-type(1):focus) {
  @apply !ring-inset !ring-brand-300;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
